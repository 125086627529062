.performer-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .performer-card:hover {
    transform: scale(1.05); /* Effetto di zoom */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5); /* Ombreggiatura più profonda */
  }
  
  .bio-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Sfondo semi-trasparente */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Nascondi inizialmente */
    transition: opacity 0.3s ease; /* Transizione per l'opacità */
  }
  
  .performer-card:hover .bio-overlay {
    opacity: 1; /* Mostra al passaggio del mouse */
  }
  